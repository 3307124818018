import Handlebars from 'handlebars';
import React, { useState } from 'react';
import Select2Component from "components/select2Component";
import agents from 'collections/agentsInstance';
import tags from 'collections/tagsInstance';
import manualTags from 'collections/manualTagsInstance';
import Spinner from 'components/Spinner.tsx';
import _ from 'underscore';
import axios from 'axios';
import Notificator from "../../libraries/notificator";

const { t } = Handlebars.helpers;
const widgetTypes = [
  { id: 1, key: "overview", localeKey: 'webapp.reader_admin.edit.media_insights.type_overview'},
  { id: 2, key: "resonance", localeKey: 'webapp.reader_admin.edit.media_insights.type_resonance'},
];
const findWidgetType = (typeId) => _.find(widgetTypes, (element) => element.id == typeId);
const lookupTypeId = (key) => {
  const widget = _.find(widgetTypes, (t) => t.key === key);
  return widget ? widget.id : null;
};
function modelsToOptions(models, type, icon) {
  if (!models || models.length === 0) return [];
  return models.map((model) => ({
    type: type,
    icon: icon,
    text: model.attributes.name,
    id: model.id,
  }));
}

const selectAgentsAndTags = (mediaInsight) => {
  const agents = mediaInsight?.agents?.map(agent => ({
      id: agent.id,
      text: agent.name,
      type: "agent",
      icon: "searchagent",
  })) || [];

  const tags =
    mediaInsight?.tags?.length > 0
      ? mediaInsight.tags.map((tag) => ({
          id: tag.id,
          text: tag.name,
          type: "tag",
          icon: tag.kind === "manual" ? "tag" : "smarttag",
        }))
      : [];

  return [...agents, ...tags];
};

export default function MediaInsightsDetail(props) {
  const { mediaInsight, configurationId, reloadLeftPanel, overviewExists } = props;
  const selectables = [
    {
      text: "Agent",
      icon: "searchagent",
      children: modelsToOptions(
        agents.filter((agent) => agent.attributes.shared === true),
        "agent",
        "searchagent"
      ),
    },
    {
      text: "Smarttag",
      icon: "smarttag",
      children: modelsToOptions(
        tags.filter((tag) => tag.kind !== "manual"),
        "tag",
        "smarttag"
      ),
    },
    {
      text: "Tag",
      icon: "tag",
      children: modelsToOptions(manualTags, "tag", "tag")
    },
  ]
    .filter((item) => item.children.length > 0);

  const [syncing, setSyncing] = useState(false);
  const [error, setError] = useState(null);
  const [type, setType] = useState(lookupTypeId(mediaInsight.type) || null);
  const [id, setId] = useState(mediaInsight.id || null);
  const [selection, setSelection] = useState(selectAgentsAndTags(mediaInsight));
  const [title, setTitle] = useState(mediaInsight.title || '');
  const [updateTimeout, setUpdateTimeout] = useState(null);
  const [deleted, setDeleted] = useState(false);

  const axiosErrorProcessing = (error) => {
    const { response } = error;
    if (response) {
      if (response.data?.error) {
        const errorMessage = `Error: ${(response.data.error)}`;
        Notificator.showErrorNotification(errorMessage);
        setError(response.data.error);
      } else {
        Notificator.showErrorNotification("Unknown error");
        setError("UnknownError");
      }
    } else {
      Notificator.showErrorNotification("Error: Network failure");
      setError("Network failure");
    }
    setSyncing(false);
  };

  const create = (typeId) => {
    setSyncing(true);
    setType(typeId);
    const widgetType = findWidgetType(typeId);
    const defaultTitle =
      widgetType.key === "overview"
        ? t("webapp.reader_admin.edit.media_insights.overview_defaultName")
        : t("webapp.reader_admin.edit.media_insights.resonance_defaultName");
    setTitle(defaultTitle);
    axios
      .post(`/api/reader/${configurationId}/reader_insights/v1`, {
        type: widgetType.key,
      })
      .then((res) => {
        setSyncing(false);
        setId(res.data.id);
        // immediately send rest of the data for update when created
        update(defaultTitle, selection, res.data.id);
        setError(null);
      })
      .catch(axiosErrorProcessing);
  }

  const update = (changedTitle, changedSelection, id) => {
    const tagIds = _.reduce(changedSelection, (memo, element) => {
      if (element.type != 'tag') return memo;
      memo.push(element.id);
      return memo;
    }, []);
    const agentIds = _.reduce(changedSelection, (memo, element) => {
      if (element.type != 'agent') return memo;
      memo.push(element.id);
      return memo;
    }, []);
    axios
      .put(`/api/reader/${configurationId}/reader_insights/v1/${id}`, {
        title: changedTitle,
        tag_ids: tagIds,
        agent_ids: agentIds,
      })
      .then((res) => {
        setSyncing(false);
        setError(null);
        reloadLeftPanel(id);
      })
      .catch(axiosErrorProcessing);
  }

  const apiDelete = (deletingId) => {
    setSyncing(true);
    axios.delete(`/api/reader/${configurationId}/reader_insights/v1/${deletingId}`).then((res) => {
      setDeleted(true);
      setSyncing(false);
      setError(null);
      reloadLeftPanel();
    }).catch(axiosErrorProcessing);
  }

  const select2Props = {
    data: selectables,
    multiple: true,
    inputName: 'selectors',
    select2Id: 'select2_selectors_input',
    preselectedItems: selection,
    selectionCallback: (_, changedSelection) => {
      setSyncing(true);
      setSelection(changedSelection);
      if (!id) {
        setSyncing(false);
      } else {
        update(title, changedSelection, id);
      };
    },
  }

  const changeTitle = (e) => {
    const { target: { value } } = e;
    setTitle(value);
    clearTimeout(updateTimeout);
    setUpdateTimeout(
      setTimeout(() => {
        setSyncing(true);
        if (!id) {
          setSyncing(false);
        } else {
          update(value, selection, id);
        };
      }, 1500
    ));
  }

  const typeControl = (
    <div className="form-item__input">
      <div>
        <a
          className={`dropdown__toggle button ${type ? "text--grey" : ""}`}
          data-toggle="dropdown"
          style={{ pointerEvents: type ? "none" : "auto" }}
        >
          <i className="icon-triangle-down float-right"></i>
          <span>
            {type
              ? t(findWidgetType(type).localeKey)
              : t("webapp.reader_admin.edit.media_insights.type_placeholder")}
          </span>
        </a>
        <ul className="dropdown__menu" role="menu">
          {widgetTypes.map((widgetType) => {
            const isOverviewDisabled = widgetType.key === "overview" && overviewExists;

            return (
              <li key={widgetType.id} className="dropdown__item" role="menuitem">
                <a
                  className={`dropdown__link ${
                    isOverviewDisabled ? "text--grey" : ""
                  }`}
                  style={{
                    pointerEvents: isOverviewDisabled ? "none" : "auto",
                  }}
                  onClick={() => {
                    if (!isOverviewDisabled) {
                      setType(widgetType.id);
                      create(widgetType.id);
                    }
                  }}
                >
                  {t(widgetType.localeKey)}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );

  if (deleted) return (
    <div className="lightened-container lifted-container pts phl pbl">
      <h2 className="heading-xlarge mvl">
        { t('webapp.reader_admin.edit.media_insights.edit_heading') }
      </h2>
      <p><i className="icon-trash"></i> { t('webapp.reader_admin.edit.media_insights.deletion_success') }</p>
    </div>
  );

  return (
    <div className="lightened-container lifted-container pts phl pbl">
      <h2 className="heading-xlarge mvl">
        {t("webapp.reader_admin.edit.media_insights.edit_heading")}
      </h2>
      {(syncing && <Spinner />) || (
        <>
          <div className="form-item mbs">
            <label className="form-item__label">
              {t("webapp.reader_admin.edit.media_insights.sub_heading")}
            </label>
            {typeControl}
          </div>
          {id && (
            <div className="form-item mbs">
              <label htmlFor="title" className="form-item__label">
                {t("webapp.reader_admin.edit.media_insights.title")}
              </label>
              <input
                className="text-input text-input--block"
                type="text"
                id="title"
                value={title}
                onChange={changeTitle}
                name="title"
              />
            </div>
          )}
          {id && findWidgetType(type)?.key !== "overview" && (
            <div className="form-item mbs">
              <label htmlFor={select2Props.select2Id} className="form-item__label">
                {t("webapp.reader_admin.edit.media_insights.agentsAndTags")}
              </label>
              <Select2Component {...select2Props} />
              <p class="mbl text--grey">{t("webapp.reader_admin.edit.media_insights.agentAndTagSubtext")}</p>
            </div>
          )}
          {id && (
            <>
              <hr className="separator mvl" />
              <button
                onClick={() => apiDelete(id)}
                className="button button--block button--negative"
              >
                <i className="icon-trash" style={{ marginRight: "3.5px" }}></i>
                {t("webapp.reader_admin.edit.media_insights.delete")}
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
}
